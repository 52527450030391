import PropTypes from 'prop-types';

import { ImageType } from './ImageType';

const { shape } = PropTypes;

export const MediaImageType = shape({
  id: PropTypes.string,
  media: PropTypes.shape({
    alt: PropTypes.string,
    title: PropTypes.string
  }),
  relationships: PropTypes.shape({
    image: ImageType,
    ogImageMetatag: shape({
      image: ImageType,
    }),
  })
});
