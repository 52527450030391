import PropTypes from 'prop-types';

const { shape, object, string } = PropTypes;

export const ImageType = shape({
  localFile: shape({
    childImageSharp: shape({
      fluid: object,
      fixed: object,
    }),
    extension: string,
    publicURL: string
  })
});
