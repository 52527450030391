import { string } from 'prop-types';
import { arrayOf, shape } from 'prop-types';

import { MediaImageType } from './MediaImageType';
import { MetatagType } from './MetatagType';

export const BlogPostType = shape({
  id: string,
  title: string,
  body: shape({ processed: string }),
  metatags: arrayOf(MetatagType),
  relationships: shape({
    image: MediaImageType,
  }),
});
