import { string } from 'prop-types';
import { arrayOf, shape } from 'prop-types';

import { MediaImageType } from './MediaImageType';
import { MetatagType } from './MetatagType';

export const ProjectType = shape({
  id: string,
  title: string,
  description: shape({ processed: string }),
  metatags: arrayOf(MetatagType),
  summary: shape({ processed: string }),
  relationships: shape({
    heroImage: MediaImageType,
    logo: MediaImageType,
    previewImage: MediaImageType,
    sections: arrayOf(shape({ type: string })),
  }),
});
