import PropTypes from 'prop-types';

const { shape, string } = PropTypes;

export const MetatagType = shape({
  tag: string,
  attributes: shape({
    name: string,
    property: string,
    content: string,
    rel: string,
    href: string
  })
});
