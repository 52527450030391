import { bool, shape, string } from 'prop-types';

export const ContactFormType = shape({
  type: string,
  heading: shape({ processed: string }),
  successMessage: shape({ processed: string }),
  errorMessage: shape({ processed: string }),
  portalID: string,
  formID: string,
  active: bool,
});
