import React from 'react';
import { arrayOf, string } from 'prop-types';
import { Helmet } from 'react-helmet';

import { MetatagType } from '../../types';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

const SEO = ({ title, metatags }) => {

  const { drupalURL, frontendURL } = useSiteMetadata();

  const getAttributes = (attributes) => {
    return Object.keys(attributes)
      .map(attrName => {
        return { name: attrName, value: attributes[attrName] };
      })
      .filter(filterAttribute)
      .map(preprocessAttribute)
      .reduce((accumulator, attribute) => {
        accumulator[attribute.name] = attribute.value;
        return accumulator;
      }, {});
  };

  const filterAttribute = (attribute) => {
    const filters = [
      attributeIsNotEmpty
    ];
    return filters.every(filter => filter(attribute));
  };

  const attributeIsNotEmpty = (attribute) => {
    return attribute.name && Boolean(attribute.value);
  };

  const preprocessAttribute = (attribute) => {
    return { name: attribute.name, value: replaceDrupalURL(attribute.value) };
  };

  const replaceDrupalURL = (value) => {
    return drupalURL && value
      ? value.replace(drupalURL, frontendURL)
      : value;
  };

  const translateToHelmetAttributes = (metatags) => {
    const helmetAttr = metatags.reduce((accumulator, metatag) => {
      accumulator[metatag.tag]?.push(getAttributes(metatag.attributes));
      return accumulator;
    }, { meta: [], link: [] });

    helmetAttr.title = helmetAttr.meta.find(metaItem => metaItem.name === 'title')?.content || title;

    return helmetAttr;
  };

  return (
    <Helmet {...translateToHelmetAttributes(metatags)} />
  );
};

SEO.propTypes = {
  title: string,
  metatags: arrayOf(MetatagType)
};

SEO.defaultProps = {
  title: '',
  metatags: []
};

export default SEO;
